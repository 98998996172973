@media screen and (min-width: 1920px) {
  .fv-rotate-box {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }

  .fv-area__back {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }

  .fv-area:before {
    width: 100vw;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
}
@media screen and (max-width: 768px) {
  body {
    -webkit-font-feature-settings: 'palt';
    font-feature-settings: 'palt';
    min-width: unset;
    max-width: 100%;
  }

  .header {
    padding: 34px 25px 0;
  }

  .logo__img {
    width: 123px;
  }

  .logo__text {
    font-size: 0.9rem;
  }

  .login-btn {
    width: 70px;
    height: 31px;
  }

  .login-btn__text {
    font-size: 1.1rem;
    top: 51%;
  }

  .frame-border,
  .frame-border-gray {
    width: 70px;
    height: 30.5px;
  }

  .fv-area__side {
    left: 1.6%;
    bottom: 44px;
  }

  .fv-area__side span {
    font-size: 0.9rem;
    margin-bottom: 54px;
    letter-spacing: 0.02em;
  }

  .fv-area__contents {
    bottom: 236px;
    left: 11%;
  }

  .fv-title {
    font-size: 1.9rem;
    margin-bottom: 14px;
    letter-spacing: 0.05em;
    -webkit-font-language-override: 1.5em;
    font-language-override: 1.5em;
  }

  .fv-text {
    font-size: 1.2rem;
    line-height: 1.666em;
    margin-bottom: 12px;
    letter-spacing: 0;
  }

  .fv-subtext {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.54em;
    letter-spacing: 0;
  }

  .fv-rotate-box {
    bottom: 113px;
  }

  .fv-rotate-box__text {
    font-size: 10rem;
    text-shadow: rgba(40, 40, 40, 0.2) 8px 12px;
  }

  .cta {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    bottom: 55px;
    width: 85.51%;
    right: 38px;
    padding: 17px 7% 62px 4%;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    border-radius: 10px;
    z-index: 3;
  }

  .cta.active {
    right: 6%;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  .cta-open {
    width: 38px;
  }

  .cta-left {
    width: 47%;
  }

  .cta__head {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .cta-right {
    width: 50%;
  }

  .cta__text {
    font-size: 1.2rem;
    letter-spacing: -0.01em;
    padding: 0 0.5em;
  }
  .cta__text br {
    display: none;
  }

  .cta__smalltext {
    font-size: 1.1rem;
    margin: 0;
    padding: 10px 0 21px;
    text-align: center;
    letter-spacing: 0.05em;
  }
  .cta__smalltext a {
    letter-spacing: 0.1em;
  }

  .cta__btn span {
    font-size: min(3vw, 12px);
    position: relative;
    bottom: 0.5px;
  }

  .cta__head {
    letter-spacing: 0.05em;
  }

  .cta__btn {
    width: 100%;
    max-width: 141px;
    height: 35px;
    letter-spacing: 0;
  }

  .cta__smalltext {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  .close-btn {
    width: 22px;
    height: 22px;
    bottom: unset;
    top: 9px;
    right: 7px;
  }

  .top-heading__title {
    font-size: 3rem;
    letter-spacing: -0.02em;
    padding-bottom: 30px;
  }

  .top-heading__title span {
    font-size: 1.1rem;
    margin-bottom: -4px;
  }

  .howto {
    padding-left: 6.04%;
    padding-right: 6.04%;
    margin-top: 81px;
  }

  .howto-shelf {
    display: block;
    margin-top: 64px;
  }

  .howto-shelf__item {
    width: 100%;
    margin-top: 51px;
  }
  .howto-shelf__item:first-of-type {
    margin-top: 0;
  }

  .howto-shelf__title {
    font-size: 1.2rem;
    letter-spacing: 0.11em;
  }

  .howto-shelf__title span {
    font-size: 1.1rem;
  }

  .howto-shelf__text {
    font-size: 1.2rem;
    line-height: 1.9em;
    letter-spacing: 0.1em;
    margin-bottom: 17px;
  }

  .howto-shelf__text-gray {
    font-size: 1.1rem;
  }

  .howto-shelf__img {
    width: 48%;
    margin: 45px auto 38px;
  }

  .howto-shelf__item.howto-shelf__item02 .howto-shelf__img {
    width: 54%;
    margin: 17px auto 3px;
  }

  .howto-shelf__item.howto-shelf__item03 .howto-shelf__img {
    width: 47%;
    margin: 44px auto 33px;
  }

  .top-aidemo {
    padding-left: 6.04%;
    margin-top: 136px;
    position: relative;
    margin-bottom: 64px;
  }

  .aidemo-shelf {
    display: block;
    padding: 265px 6.7% 66px 13%;
  }

  .top-aidemo > .top-heading {
    display: none;
  }

  .top-heading.top-heading-none {
    display: block;
    position: absolute;
    margin-bottom: 61px;
    z-index: 1;
    width: 100%;
    padding: 0 6% 0 18%;
    top: 57px;
    left: 0;
  }
  .top-heading.top-heading-none * {
    color: #232323 !important;
  }
  .top-heading.top-heading-none p {
    font-size: 1.2rem;
    margin-top: 12px;
    line-height: 2em;
    letter-spacing: 0.11em;
  }
  .top-heading.top-heading-none .white-line {
    background: #000000;
  }
  .top-heading.top-heading-none .top-heading__title.top-heading__title-ai {
    padding-bottom: 27px;
  }
  .top-heading.top-heading-none .top-heading__title.top-heading__title-ai span {
    font-size: 1.1rem;
  }

  .aidemo-item {
    width: 100%;
    margin-top: 46px;
  }
  .aidemo-item:nth-of-type(1) {
    margin-top: 0;
  }
  .aidemo-item:nth-of-type(2),
  .aidemo-item:nth-of-type(3) {
    margin-top: 46px;
  }

  .aidemo-item__head,
  .aidemo-item__head span {
    font-size: 1.2rem;
  }

  .aidemo-item__head {
    margin-bottom: 11px;
  }

  .aidemo-item__title {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .aidemo-item__text {
    font-size: 1.2rem;
    line-height: 1.8em;
    letter-spacing: 0.05em;
    margin-bottom: 23px;
    min-height: unset;
  }

  .aidemo-item__btn {
    font-size: 1.3rem;
    width: 131px;
    height: 40px;
  }

  .aidemo-item__thumb {
    padding: 7.9% 7.2%;
    margin-bottom: 23px;
  }

  .aidemo-item__thumb-frame {
    padding-bottom: 69.5%;
  }

  .play-btn {
    width: 70px;
    height: 70px;
    bottom: 10px;
    right: 10px;
  }

  .aidemo-item.aidemo-item-soon .aidemo-item__thumb-pic span {
    font-size: 1.2rem;
  }

  .footer {
    padding-right: 5.25%;
    padding-bottom: 40px;
  }
  .footer .frame-border,
  .footer .frame-border-gray {
    width: 114px;
    height: 40px;
  }

  .footer-inner__shelf {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .footer-inner__shelf .logo {
    display: block;
    width: 187px;
  }
  .footer-inner__shelf .logo img {
    width: 100%;
    margin-bottom: 12px;
  }
  .footer-inner__shelf .logo .logo__text {
    font-size: 1.2rem;
    letter-spacing: 0.07em;
  }

  .footer-inner__shelf .login-btn {
    width: 114px;
    height: 40px;
    margin-left: 0;
    position: relative;
    bottom: 10px;
  }
  .footer-inner__shelf .login-btn svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .footer-inner__shelf .login-btn__text {
    font-size: 1.2rem;
    top: 48%;
    left: 52%;
  }
  .footer-inner__shelf .login-btn__text img {
    margin-left: 4px;
  }

  .copy-right {
    margin-top: 103px;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
  }

  .page-top {
    font-size: 1.2rem;
    right: 18px;
    bottom: -1px;
  }

  .under .header {
    padding: 35px 25px 30px 26px;
  }
  .under .header .logo__img {
    margin-bottom: 6px;
  }
  .under .header .logo__text {
    font-size: 0.9rem;
    letter-spacing: -0.01em;
  }
  .under .header .login-btn,
  .under .header .frame-border,
  .under .header .frame-border-gray {
    width: 72px;
    height: 30px;
  }
  .under .header .login-btn {
    position: relative;
    bottom: 8px;
  }

  .under .footer {
    padding: 66px 5.5% 38px 9.6%;
  }
  .under .footer .login-btn {
    bottom: 11px;
  }
  .under .footer .page-top {
    right: 16px;
  }

  .error-page {
    padding-top: 232px;
    padding-bottom: 220px;
  }

  .error-page__head {
    margin: 0 0 30px;
    font-size: 4rem;
    font-weight: 500;
  }

  .error-page__subhead {
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    margin-bottom: 14px;
  }

  .error-page__text {
    font-size: 1.2rem;
    line-height: 2em;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
  }

  .error-page .login-item__btn {
    font-size: 1.3rem;
    width: 189px;
    height: 50px;
  }

  .modal-wrapper .modal-window {
    width: 90%;
    border-radius: 20px;
    min-width: unset;
    padding: 10% 4%;
  }

  .modal-wrapper .modal-window .modal-content.movie {
    width: 100%;
    padding-bottom: 56%;
    position: relative;
  }

  .modal-content iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-wrapper .modal-close.close-btn {
    width: 22px;
    height: 22px;
    top: 10px;
    right: 10px;
  }
  .modal-wrapper .modal-close.close-btn .close-btn__cross {
    width: 13px;
  }
  .modal-wrapper .modal-close.close-btn svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
