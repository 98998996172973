@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  94% {
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
@-webkit-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.slide-wrap {
  width: 100%;
  height: 100%;
}

.slide-in-right {
  opacity: 0;
  background: none;
  animation: slideInRight 0.5s ease-out 0s 1 forwards;
  -webkit-animation: slideInRight 0.5s ease 0s 1 forwards;
}

.slide-out-right {
  background: none;
  animation: slideOutRight 0.5s ease-out 0s 1 forwards;
  -webkit-animation: slideOutRight 0.5s ease 0s 1 forwards;
}

.hidden {
  visibility: hidden;
}

.slide-in-right .wf-active {
  opacity: 0;
  animation: fadeIn 0.5s ease 0s 1 forwards;
  -webkit-animation: fadeIn 0.5s ease 0s 1 forwards;
}

.slide-in-right .wf-active.delay {
  opacity: 0;
  animation: fadeIn 0.5s ease 0s 1 forwards;
  -webkit-animation: fadeIn 0.5s ease 0s 1 forwards;
  animation-delay: 0.5s;
}

.login-item-forgot {
  font-size: 1.2rem;
  line-height: 2.25em;
  letter-spacing: -0.06em;
  margin-top: 25px;
}

.login-item-forgot > a {
  text-decoration: underline;
}

/* disabled時の「読み上げる」ボタン */
.read-about-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-color: transparent;
}
.read-about-disabled img {
  opacity: 0;
}
.read-about-disabled .read-about__hover-icon {
  opacity: 1;
}
.read-about-disabled span {
  color: #806f4c;
}
.read-about-disabled span:before {
  background: #806f4c;
}

/* disabled時の「上記設定で動画を生成する」ボタン */
.generation-btn-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background: transparent;
  color: #282828;
}
.generation-btn-disabled:before {
  background: url(/public/img/generation_icon_b.svg);
}

.log-number-opacity {
  opacity: 0.2;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

@media screen and (min-width: 1080px) {
  html {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
}

.position-static {
  position: static !important;
}

.ai-space {
  margin: 0.3rem;
}

.menu-inner .icon-shelf__box:last-of-type img {
  top: unset;
  left: unset;
}

.modal-wrapper.modal-hide {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}

.modal-wrapper.modal-show {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.4s, visibility 0.4s;
  transition: opacity 0.4s, visibility 0.4s;
}

.aidemo-item__thumb,
.report-modal,
.aidemo-item__btn-modal {
  cursor: pointer;
}

.login-btn-cursor {
  cursor: pointer;
}

.cta__smalltext div {
  position: relative;
  font-weight: 700;
  display: inline-table;
  letter-spacing: -0.01em;
  margin-left: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media screen and (max-width: 768px) {
  .cta__smalltext div {
    letter-spacing: 0.1em;
  }
}
.cta__smalltext div:hover {
  opacity: 0.5;
}
.cta__smalltext div:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background: #d7d7d7;
  left: 0;
  bottom: -2px;
}
