@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://ericmeyer.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

/* Responsive images and other embedded objects
Note: keeping IMG here will cause problems if you're using foreground images as sprites.
If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%;
}

/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

::marker {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type='radio'] {
  vertical-align: text-bottom;
}

input[type='checkbox'] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

/* Accessible focus treatment
	people.opera.com/patrickl/experiments/keyboard/test */
a:hover,
a:active {
  outline: none;
}

small {
  font-size: 85%;
}

strong,
th {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

/* Make sure sup and sub don't screw with your line-heights
	gist.github.com/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover */
.ie6 html {
  -webkit-filter: expression(document.execCommand('BackgroundImageCache', false, true));
  filter: expression(document.execCommand('BackgroundImageCache', false, true));
}

/* let's clear some floats */
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/
/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
body {
  font: 13px Helmet, Freesans, sans-serif;
}

/* using local fonts? make sure to read up on Paul Irish's
	Bulletproof @font-face syntax: http://mky.be/font-face/bulletproof/ */
/* we like off-black for text */
body,
select,
input,
textarea {
  color: #333333;
}

a {
  color: #333333;
}

/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
::-moz-selection {
  background: #939393;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #939393;
  color: #fff;
  text-shadow: none;
}

/*	j.mp/webkit-tap-highlight-color */
a:link {
  -webkit-tap-highlight-color: #fcd700;
}

ins {
  background-color: #fcd700;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #fcd700;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

/* HTML5 Reset end
-------------------------------------------------------------------------------*/
/*
Standard Classes
Author:Takuro Hishikawa
*/
strong {
  font-weight: bold;
}

.nomargin {
  margin: 0 !important;
}

.noborder {
  border: 0 !important;
}

.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.clearboth {
  clear: both;
}

.clearleft {
  clear: left;
}

.clearright {
  clear: right;
}

img.alignleft,
.floatleft {
  float: left;
  margin: 0 10px 10px 0;
}

img.alignright,
.floatright {
  float: right;
  margin: 0 0 10px 10px;
}

input[type='submit'],
input[type='button'] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 600;
}
input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none;
}
input[type='submit']::focus,
input[type='button']::focus {
  outline-offset: -2px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04em;
  color: #282828;
}

html {
  font-size: 62.5%;
  color: #000000;
  opacity: 0;
}

html.wf-active {
  opacity: 1;
  animation: fadeIn 0.5s ease 0s 1 normal;
  -webkit-animation: fadeIn 4s ease 0s 1 normal;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  font-size: 1.6rem;
  position: relative;
  background: #000000;
  max-width: 1920px;
  min-width: 1080px;
  margin: 0 auto;
}

body.fixed {
  position: fixed;
}

.back-dots {
  position: relative;
  width: 100%;
  height: 100%;
}
.back-dots:before {
  position: absolute;
  content: '';
  height: 100%;
  top: 0;
  left: 0;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  background-image: url(/public/img/back-dots.png);
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
}

.fadein {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.fadein2 {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.fadein3 {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.fadein4 {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

/**********
  見出し・テキスト設定
********************************************************************/
/**********
  ヘッダー
********************************************************************/
.header {
  padding: 60px 45px 60px 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.logo__img {
  width: 246px;
  margin-bottom: 14px;
}

.logo__text {
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  display: block;
  font-size: 1.6rem;
  opacity: 0.5;
}

.login-btn {
  width: 140px;
  height: 60px;
  display: block;
  position: relative;
}
.login-btn svg {
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.login-btn:hover .login-btn__link {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.login-btn:hover svg {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.login-btn:hover .frame-border {
  stroke-dashoffset: 0;
}
.login-btn:hover .frame-border-gray {
  -webkit-animation: login-btn-remove 0.2s ease-in 0.4s 1 normal;
  animation: login-btn-remove 0.2s ease-in 0.4s 1 normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes login-btn-remove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes login-btn-remove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.frame-border,
.frame-border-gray {
  width: 140px;
  height: 61px;
}

.login-btn__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 100px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.login-btn__text {
  color: #ffffff;
  font-size: 1.3rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.frame-border {
  stroke-dasharray: 350px;
  stroke-dashoffset: 350px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.frame-border-gray {
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

/**********
  FV
********************************************************************/
.fv-area {
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 600px;
}
.fv-area:before {
  position: absolute;
  content: '';
  background-image: url(/public/img/back-dots.png);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.fv-area__back {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
}
.fv-area__back:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
}

.fv-area__back.fv-area__back-01:before {
  background-image: url(/public/img/fv-01.jpg);
}

.fv-area__back.fv-area__back-02:before {
  background-image: url(/public/img/fv-02.jpg);
}

.fv-area__back.fv-area__back-03:before {
  background-image: url(/public/img/fv-03.jpg);
}

.fv-area__back:before {
  -webkit-animation: anime 9s 0s infinite;
  animation: anime 9s 0s infinite;
}

.fv-area__back.fv-area__back-01:before {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.fv-area__back.fv-area__back-02:before {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.fv-area__back.fv-area__back-03:before {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

@-webkit-keyframes anime {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes anime {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}
.fv-area__contents {
  position: absolute;
  z-index: 2;
  left: 9.4%;
  bottom: 144px;
}
.fv-area__contents * {
  color: #ffffff;
}

.fv-title {
  font-size: 3.6rem;
  margin-bottom: 25px;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 1.33em;
}

.fv-text {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 17px;
  line-height: 2.06em;
  letter-spacing: 0.05em;
}

.fv-subtext {
  opacity: 0.2;
  font-size: 1.6rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 2em;
  letter-spacing: 0.01em;
}

.fv-area__side {
  position: absolute;
  z-index: 2;
  bottom: 127px;
  left: 3.7%;
}
.fv-area__side span {
  font-size: 1.1rem;
  color: #ffffff;
  opacity: 0.2;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  display: table;
  margin-bottom: 22px;
  letter-spacing: 0.06em;
}
.fv-area__side span:last-of-type {
  margin-bottom: 0;
}

.fv-rotate-box {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.fv-rotate-shelf {
  margin: 0;
  margin-right: 60px;
  display: inline-block;
  white-space: nowrap;
  line-height: 1em;
  -webkit-animation: fv-rotate 30s linear infinite;
  animation: fv-rotate 30s linear infinite;
}

.fv-rotate-box__text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  display: inline;
  margin: 0 0px 0 0;
  font-size: 20rem;
  color: rgba(40, 40, 40, 0.5);
  text-shadow: rgba(40, 40, 40, 0.2) 17px 25px;
  line-height: 1;
}

@-webkit-keyframes fv-rotate {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes fv-rotate {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
/**********
  HOW TO USE
********************************************************************/
.howto {
  width: 100%;
  padding-left: 9.5%;
  padding-right: 6.25%;
  margin-top: 110px;
}

.top-heading__title {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  font-size: 4.6rem;
  color: #ffffff;
  padding-bottom: 32px;
  letter-spacing: 0;
}
.top-heading__title span {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04em;
  opacity: 0.4;
  font-size: 1.4rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04em;
  display: block;
  margin-bottom: 6px;
  color: #ffffff;
}

.white-line {
  width: 100%;
  height: 1px;
  background: #ffffff;
  display: block;
}

.howto-shelf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 95px;
}

.howto-shelf__item {
  width: 28.93%;
}
.howto-shelf__item * {
  color: #ffffff;
}

.howto-shelf__item02 .howto-shelf__img {
  width: 50%;
  margin: 40px auto 35px;
}

.howto-shelf__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
  padding-bottom: 16px;
  letter-spacing: 0.03em;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}
.howto-shelf__title span {
  font-size: 1.4rem;
  opacity: 0.4;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
}

.howto-shelf__img {
  width: 44%;
  margin: 55px auto 43px;
}

.howto-shelf__text {
  font-size: 1.6rem;
  margin-bottom: 27px;
  line-height: 1.7em;
  letter-spacing: 0.053em;
}

.howto-shelf__text-gray {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  opacity: 0.2;
  letter-spacing: 0.05em;
}

/**********
  AI DEMO
********************************************************************/
.top-aidemo {
  width: 100%;
  padding-left: 9.5%;
  padding-right: 6.25%;
  margin-top: 149px;
  padding-right: 0;
  margin-bottom: 100px;
}

.top-heading__title.top-heading__title-ai {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 6.3%;
  color: #ffffff;
}
.top-heading__title.top-heading__title-ai span {
  font-size: 1.6rem;
  opacity: 1;
  font-weight: 700;
  width: 100%;
  color: #ffffff;
  margin-bottom: -3px;
  letter-spacing: 0.06em;
}
.top-heading__title.top-heading__title-ai p {
  font-size: 1.6rem;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #ffffff;
  margin-top: 16px;
  letter-spacing: 0.02em;
}

.aidemo-shelf {
  background-image: url(/public/img/bg-aidemo.png);
  padding: 114px 100px 120px 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.top-heading.top-heading-none {
  display: none;
}

.aidemo-item {
  width: 28.74%;
  margin-top: 115px;
}
.aidemo-item:nth-of-type(1),
.aidemo-item:nth-of-type(2),
.aidemo-item:nth-of-type(3) {
  margin-top: 0;
}

.aidemo-item__head {
  font-size: 1.8rem;
  color: #232323;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  margin-bottom: 13px;
  letter-spacing: 0;
}
.aidemo-item__head span {
  letter-spacing: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.aidemo-item__thumb {
  background: #f1f1f1;
  padding: 8.82% 7.35%;
  width: 100%;
  display: block;
  margin-bottom: 33px;
  position: relative;
}
.aidemo-item__thumb:hover .aidemo-item__thumb-pic {
  -webkit-transform: scale(1.05) rotate(0.001deg);
  transform: scale(1.05) rotate(0.001deg);
}
.aidemo-item__thumb:hover .play-btn:before {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.play-btn__inner {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.play-btn {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.play-btn:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 100%;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.play-btn__inner {
  margin-top: 7px;
  z-index: 1;
}

.play-btn__icon {
  width: 14px;
  margin: 0 auto 7px 12px;
  opacity: 0.6;
}

.play-btn__text {
  font-size: 1.2rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  color: #ffffff;
  opacity: 0.4;
  display: block;
  text-align: center;
  line-height: 1;
}

.aidemo-item__thumb-frame {
  width: 100%;
  padding-bottom: 68.6%;
  overflow: hidden;
  position: relative;
}

.aidemo-item__thumb-pic {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(1) rotate(0.001deg);
  transform: scale(1) rotate(0.001deg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.aidemo-item__title {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 27px;
  color: #232323;
  letter-spacing: 0em;
}

.aidemo-item__text {
  font-size: 1.6rem;
  margin-bottom: 33px;
  color: #232323;
  letter-spacing: 0.023em;
  line-height: 1.67em;
  min-height: 80.16px;
  font-weight: 400;
}

.aidemo-item__btn {
  background: #282828;
  width: 176px;
  height: 60px;
  border-radius: 100px;
  border: solid 1px #282828;
  color: #ffffff;
  font-size: 1.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.aidemo-item__btn:hover {
  background: transparent;
  color: #232323;
}

.aidemo-item.aidemo-item-soon * {
  pointer-events: none;
}
.aidemo-item.aidemo-item-soon .aidemo-item__head,
.aidemo-item.aidemo-item-soon .play-btn,
.aidemo-item.aidemo-item-soon .aidemo-item__title,
.aidemo-item.aidemo-item-soon .aidemo-item__text,
.aidemo-item.aidemo-item-soon .aidemo-item__btn {
  opacity: 0.3;
}
.aidemo-item.aidemo-item-soon .aidemo-item__thumb {
  background: rgba(241, 241, 241, 0.3);
}
.aidemo-item.aidemo-item-soon .aidemo-item__thumb-pic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(225, 222, 222, 0.3);
}
.aidemo-item.aidemo-item-soon .aidemo-item__thumb-pic span {
  color: #232323;
  font-size: 1.8rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  letter-spacing: 0;
}

.modal-wrapper {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-wrapper:not(:target) {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}

.modal-wrapper:target {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.4s, visibility 0.4s;
  transition: opacity 0.4s, visibility 0.4s;
}

.modal-wrapper .modal-window {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  z-index: 20;
  position: relative;
  min-width: 800px;
  padding: 97px 115px;
  border-radius: 60px;
  background: #fff;
}

.modal-wrapper .modal-window .modal-content {
  overflow-y: auto;
}

.modal-content iframe {
  width: 845px;
  height: 492px;
}
.modal-content .bold-text {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  line-height: 2.25em;
  margin-bottom: 40px;
}
.modal-content .normal-text {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.25em;
  color: rgba(40, 40, 40, 0.7);
  text-align: center;
}
.modal-content .normal-text a {
  position: relative;
  color: inherit;
}
.modal-content .normal-text a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background: rgba(40, 40, 40, 0.7);
  bottom: -2px;
  left: 0;
}

.aidemo-item__btn.aidemo-item__btn-modal {
  width: 260px;
  font-size: 1.4rem;
  margin: 56px auto 0;
}

.modal-overlay {
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(35, 35, 35, 0.85);
}

.modal-wrapper .modal-close.close-btn {
  z-index: 20;
  width: 60px;
  height: 60px;
  top: 40px;
  right: 40px;
}
.modal-wrapper .close-btn__cross {
  width: 24px;
}

.modal-wrapper .modal-close:hover {
  color: #2b2e38 !important;
}

/**********
  footer
********************************************************************/
.footer {
  width: 100%;
  padding-left: 9.5%;
  padding-right: 6.25%;
  padding-bottom: 95px;
}

.footer-inner {
  position: relative;
}

.footer-inner__shelf {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-inner__shelf .frame-border,
.footer-inner__shelf .frame-border-gray {
  width: 160px;
  height: 60px;
}
.footer-inner__shelf .logo__img {
  width: 216px;
}
.footer-inner__shelf .logo__text {
  letter-spacing: 0.07em;
}
.footer-inner__shelf .login-btn {
  width: 160px;
  margin-left: 150px;
}
.footer-inner__shelf .login-btn__text {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-weight: 700;
  font-size: 1.4rem;
  top: 49%;
  left: 53%;
}
.footer-inner__shelf .login-btn__text img {
  width: 9px;
  margin-left: 9px;
}
.footer-inner__shelf .frame-border {
  stroke-dasharray: 390px;
  stroke-dashoffset: 390px;
}

.copy-right {
  font-size: 1.1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.4;
  margin-top: 71px;
  display: block;
  letter-spacing: 0.06em;
}

.page-top {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.4rem;
  position: absolute;
  right: 41px;
  bottom: -2px;
  z-index: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.page-top:hover {
  opacity: 0.5;
}

/**********
  CTA
********************************************************************/
.cta {
  width: 627px;
  position: fixed;
  right: 50px;
  bottom: 120px;
  z-index: 3;
  background: #ffffff;
  border-radius: 20px;
  border: solid 1px #000000;
  padding: 36px 40px 40px 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(623px);
  transform: translateX(623px);
}

.cta-open {
  position: absolute;
  height: 100%;
  width: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: visible;
  cursor: pointer;
}
.cta-open span {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: 1;
  letter-spacing: 0.13em;
  font-size: 1.2rem;
  font-weight: 700;
}

.cta.active {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}
.cta.active .cta-open {
  opacity: 0;
  visibility: hidden;
}
.cta.active .cta-left {
  opacity: 1;
  visibility: visible;
}
.cta.active .cta-right {
  opacity: 1;
  visibility: visible;
}

.cta-left {
  width: 33.7%;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.cta-right {
  width: 60.4%;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
}

.cta__head {
  font-size: 1.9rem;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 0.01em;
}

.cta__btn {
  width: 180px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ffffff;
  background: #000000;
  border-radius: 100px;
  border: solid 1px #000000;
  margin: 0 auto;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.cta__btn span {
  color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.cta__btn:hover {
  background: transparent;
}
.cta__btn:hover span {
  color: #000000;
}

.cta__text {
  font-size: 1.4rem;
  letter-spacing: -0.04em;
}

.cta__smalltext {
  font-size: 1.2rem;
  margin-top: 22px;
  letter-spacing: -0.01em;
}
.cta__smalltext a {
  position: relative;
  font-weight: 700;
  display: inline-table;
  letter-spacing: -0.01em;
  margin-left: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.cta__smalltext a:hover {
  opacity: 0.5;
}
.cta__smalltext a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background: #d7d7d7;
  left: 0;
  bottom: -2px;
}

.close-btn {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  bottom: 16px;
  right: 13px;
  border-radius: 100px;
  cursor: pointer;
}
.close-btn:hover .close-btn__circle {
  stroke-dashoffset: 0px;
  -webkit-animation: close-btn 0.2s ease-in 0.4s 1 normal;
  animation: close-btn 0.2s ease-in 0.4s 1 normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes close-btn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes close-btn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.close-btn:hover .close-btn__border {
  -webkit-animation: close-btn-none 0.2s ease-in 0.4s 1 normal;
  animation: close-btn-none 0.2s ease-in 0.4s 1 normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes close-btn-none {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes close-btn-none {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.close-btn__border {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.close-btn__circle {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  stroke-dasharray: 91px;
  stroke-dashoffset: 91px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.close-btn__cross {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.close-btn__cross:before,
.close-btn__cross:after {
  position: absolute;
  content: '';
  background: #000000;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
}
.close-btn__cross:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.close-btn__cross:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

/**********
  下層ページ
********************************************************************/
.under .header {
  padding: 35px 48px 25px 67px;
}
.under .header .logo__text {
  letter-spacing: 0.06em;
}
.under .header .login-btn {
  height: 50px;
  position: relative;
  bottom: 5px;
}
.under .footer {
  padding: 120px 145px;
}
.under .footer .page-top {
  right: -1px;
}
.under .page-top {
  display: block;
}

.icon-shelf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  bottom: 5px;
}

.icon-shelf__box {
  margin-right: 30px;
  position: relative;
  cursor: pointer;
}
.icon-shelf__box:hover .icon-shelf__text {
  opacity: 1;
}
.icon-shelf__box img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.icon-shelf__box:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background: #000000;
  display: block;
  bottom: -12px;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}
.icon-shelf__box .icon-shelf__text {
  font-size: 1.2rem;
  text-align: center;
  color: #ffffff;
  display: block;
  position: absolute;
  width: 70px;
  top: -7px;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}
.icon-shelf__box:hover img {
  opacity: 0.5;
}
.icon-shelf__box:hover:before {
  opacity: 1;
}
.icon-shelf__box img {
  width: 35px;
  margin: 0 auto;
}
.icon-shelf__box:last-of-type {
  margin-right: 0;
}

/**********
  ログイン画面
********************************************************************/
.bg-rough {
  background: url(/public/img/bg-aidemo.png);
}

.login-page {
  width: 100%;
  padding: 117px 6.25% 180px;
}

.login-page .top-heading__title.top-heading__title-ai {
  color: #232323;
  display: block;
  padding-bottom: 0;
}
.login-page .top-heading__title.top-heading__title-ai span {
  color: #232323;
}
.login-page .top-heading__title.top-heading__title-ai span {
  font-size: 1.4rem;
  margin-bottom: -4px;
  letter-spacing: 0.01em;
}
.login-page .top-heading__title.top-heading__title-ai p {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #282828;
  letter-spacing: -0.01em;
}

.login-shelf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 91px;
}

.login-item {
  width: 42.14%;
}

.login-item__heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 0em;
}

.login-item__contents {
  min-height: 310px;
  padding: 0 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-item.login-item-left .login-item__contents {
  position: relative;
}
.login-item.login-item-left .login-item__contents:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  top: 6px;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.login-item.login-item-left .login-item__text {
  position: relative;
  bottom: 14px;
}

.login-item__text {
  font-size: 1.6rem;
  line-height: 2.25em;
  text-align: center;
  letter-spacing: -0.06em;
}

.login-item__btn {
  width: 260px;
  height: 60px;
  background: #000000;
  border: solid 1px #000000;
  border-radius: 100px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px auto 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.login-item__btn:hover {
  background: transparent;
  color: #000000;
}

.login-item.login-item-right .login-item__contents__inner {
  max-width: 430px;
  margin: 0 auto;
}
.login-item.login-item-right .login-item__text {
  margin-bottom: 20px;
  letter-spacing: -0.04em;
}

.loginform-shelf {
  width: 100%;
  margin: 16px auto 0;
}
.loginform-shelf:first-of-type {
  margin-top: 0;
}
.loginform-shelf input {
  border: solid 1px rgba(0, 0, 0, 0.2);
  font-size: 1.4rem;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  padding: 14px 15px;
  letter-spacing: 0.01em;
}
.loginform-shelf input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.loginform-shelf input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.loginform-shelf input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.loginform-shelf input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.loginform-shelf input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.loginform-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 11px;
  letter-spacing: -0.06em;
}

.error-text {
  font-size: 1.2rem;
  font-weight: 700;
  color: #fb4949;
  letter-spacing: -0.06em;
}

/**********
  404ページ
********************************************************************/
.error-page {
  width: 100%;
  padding: 117px 6.25% 180px;
  padding-top: 180px;
}
.error-page .login-item__btn {
  margin-top: 0;
}

.error-bg {
  width: 100vw;
  height: 100vh;
  background: url(/public/img/bg-error.jpg);
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.error-page__head {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  font-size: 9.2rem;
  letter-spacing: 0;
  margin: -10px 0 62px;
  color: #232323;
  line-height: 1;
  letter-spacing: -0.04em;
}

.error-page__subhead {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 44px;
  letter-spacing: 0em;
}

.error-page__text {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  line-height: 2.25em;
  margin-bottom: 69px;
  letter-spacing: 0;
}

/**********
  マイページ
********************************************************************/
.mypage-contens {
  width: 100%;
  padding: 117px 6.25% 180px;
}

#mypage .top-heading__title {
  color: #232323;
  margin-right: 0;
  padding-bottom: 31px;
}
#mypage .top-heading__title span {
  color: #282828;
}
#mypage .top-heading__title p {
  color: #282828;
  margin-top: 12px;
  letter-spacing: 0;
}
#mypage .aidemo-item__btn {
  width: 156px;
  position: relative;
  left: 2px;
}
#mypage .play-btn {
  bottom: 13px;
  right: 12px;
}
#mypage .play-btn .play-btn__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0;
}
#mypage .play-btn .play-btn__text {
  font-size: 1.4rem;
  margin-right: 4px;
}
#mypage .play-btn .play-btn__icon {
  width: 6px;
  margin: 0;
}
#mypage .aidemo-item__text {
  margin-bottom: 34px;
  min-height: unset;
  line-height: 1.72em;
}
#mypage .white-line {
  background: #000000;
}
#mypage .aidemo-shelf {
  padding: 93px 0 0;
}
#mypage .aidemo-item {
  width: 25.81%;
}
#mypage .aidemo-item__thumb {
  padding: 8.5% 6.8% 8.9% 7.5%;
  margin-bottom: 30px;
}
#mypage .aidemo-item__thumb-frame {
  padding-bottom: 68.7%;
}

_::-webkit-full-page-media,
_:future,
:root #mypage .aidemo-item__thumb-frame {
  padding-bottom: 68.5%;
}

#menu .close-btn {
  width: 60px;
  height: 60px;
  top: 46px;
  left: 46px;
  bottom: unset;
  right: unset;
}
#menu .close-btn__cross {
  width: 24px;
}
#menu .icon-shelf {
  position: absolute;
  top: 45px;
  right: 48px;
  bottom: unset;
}
#menu .icon-shelf__text {
  color: #000000;
}
#menu .icon-shelf__box.point:before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background: #000000;
  display: block;
  bottom: -12px;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  opacity: 1;
}
#menu .aidemo-item__btn {
  font-size: 1.4rem;
  width: 300px;
  margin-top: 66px;
  background: #000000;
}
#menu .aidemo-item__btn:hover {
  color: #000000;
  background: transparent;
}

.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(35, 35, 35, 0.85);
  top: 0;
  left: 0;
  z-index: 10000;
}

.menu-inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 47.4%;
  height: 100%;
  background: #ffffff;
  padding-top: 130px;
}

.menu-inner .icon-shelf__box {
  margin-right: 29px;
}
.menu-inner .icon-shelf__box:last-of-type {
  margin-right: 0;
}
.menu-inner .icon-shelf__box:last-of-type img {
  position: relative;
  top: -2px;
  left: 1px;
}

.menu-contents {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 15.9% 6.2% 50px 14.7%;
}

.menu-contents__head {
  font-size: 1.4rem;
  padding-bottom: 27px;
  border-bottom: solid 1px #f1f1f1;
  margin-bottom: 32px;
}

.menu-table {
  margin-top: -4px;
}

.menu-table__tr {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 13px;
}
.menu-table__tr:first-of-type {
  margin-top: 0;
}

.menu-table__th {
  font-size: 1.4rem;
  width: 137px;
}

.menu-table__td {
  font-size: 1.6rem;
}

.menu-inner .icon-shelf__box:hover .icon-shelf__text {
  opacity: 0;
}

.log-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 53px;
}
.log-box:first-of-type {
  margin-top: 0;
}

.log-box__thumb {
  width: 59.7%;
  background: #f1f1f1;
  padding: 5.14% 4.1%;
  display: block;
}

.log-box__thumb.-generating {
  position: relative;
}
.log-box__thumb.-generating:before {
  position: absolute;
  content: '生成中';
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 700;
  color: #ffffff;
  background: rgba(35, 35, 35, 0.85);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1;
}

.log-box__thumb-frame {
  width: 100%;
  padding-bottom: 68.3%;
  overflow: hidden;
  position: relative;
}

.log-box__thumb-pic {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.log-box__contents {
  width: 33.2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.log-box__subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  color: rgba(35, 35, 35, 0.2);
  line-height: 1;
  margin-top: -3px;
}

.log-box__subtitle-number {
  display: block;
  margin-top: 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.log-box__title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 15px;
}

.log-box__contents-bottom {
  position: relative;
  top: 5px;
}

.log-box__date-head {
  font-size: 1.4rem;
  color: rgba(40, 40, 40, 0.5);
}

.log-box__date,
.log-box__time {
  font-size: 1.6rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.log-box__date {
  margin-top: 7px;
}

.log-box__time {
  margin-top: 3px;
}

#menu .aidemo-item__btn.config-btn {
  margin-top: 0;
  width: 360px;
}
#menu .aidemo-item__btn.config-btn.config-btn-white {
  margin-top: 30px;
  background: transparent;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#menu .aidemo-item__btn.config-btn.config-btn-white:hover {
  opacity: 0.5;
}

/**********
  AI DEMOページ
********************************************************************/
.aidemo-page {
  width: 100%;
  padding: 117px 6.25% 180px;
}

.oversized-number {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: -3px;
}

.oversized-subtitle {
  font-size: 4.6rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  color: #232323;
  margin: -6px 0 6px;
  letter-spacing: 0;
}

.oversized-title {
  font-size: 8rem;
  font-weight: 700;
  margin-bottom: 41px;
  letter-spacing: -0.08em;
  position: relative;
  left: -14px;
}

.under-line {
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(40, 40, 40, 0.4);
}

.report {
  padding: 53px 10% 176px;
}

.report.report-pb0 {
  padding-bottom: 0;
}

.report__text {
  font-size: 1.6rem;
  color: #232323;
  line-height: 1.74em;
  margin-bottom: 39px;
  letter-spacing: 0.023em;
}

.report-modal {
  display: block;
  width: 99.6%;
  padding-bottom: 55.4%;
  overflow: hidden;
  position: relative;
  margin: 74px auto 0;
}
.report-modal .play-btn {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.report-modal .play-btn span {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}
.report-modal .play-btn span img {
  width: 8px;
  margin-left: 6px;
  opacity: 0.6;
}
.report-modal:hover .report-modal__pic {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
.report-modal:hover .play-btn:before {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.report-modal__pic {
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute;
  top: 0;
  left: 0;
}

.aidemo-subhead-sup {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: -2px;
  text-align: right;
  letter-spacing: 0em;
}

.aidemo-subhead {
  font-size: 4.2rem;
  font-weight: 700;
  margin-bottom: 41px;
  text-align: right;
}

.report__head {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.choice-shelf {
  margin-top: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 34px;
}

.choosing {
  width: 61.7%;
  padding-bottom: 34.5%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.choosing__text {
  position: absolute;
  top: 20px;
  left: 25px;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0em;
}

.select-box {
  width: 34.7%;
  position: relative;
}

.select-title {
  text-align: center;
  padding-right: 3.5%;
  margin-top: -6px;
  font-size: clamp(1rem, 1vw, 1.6rem);
  letter-spacing: -0.02em;
}

.select-box__scroll {
  width: 100%;
  padding-right: 6.5%;
  overflow: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 88%;
}

.select-item {
  width: 48.2%;
  margin-top: 14px;
  cursor: pointer;
}
.select-item:nth-of-type(odd) {
  margin-right: 2.9%;
}
.select-item:nth-of-type(1),
.select-item:nth-of-type(2) {
  margin-top: 0;
}

.select-item__fuman {
  width: 100%;
  padding-bottom: 68.3%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.select-item__name {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 8px;
  letter-spacing: 0;
}

.select-item-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(35, 35, 35, 0.54);
  border: solid 4px #806f4c;
}

.input-box {
  background: #f1f1f1;
  padding: 28px 22px 20px;
  margin-bottom: 49px;
}

.input-box__text {
  font-size: 1.8rem;
  font-weight: 700;
  padding-left: 20px;
  letter-spacing: -0.02em;
}
.input-box__text span {
  font-size: 1.3rem;
  display: inline-table;
  vertical-align: middle;
  margin-left: 3px;
  letter-spacing: -0.01em;
}

.input-field {
  margin-top: 30px;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  padding: 19px;
  border: solid 1px rgba(40, 40, 40, 0.2);
}
.input-field textarea {
  width: 100%;
  border: none;
  padding: 7px 12px;
  font-size: 1.6rem;
  height: 194px;
  resize: none;
  letter-spacing: 0.03em;
}
.input-field .under-line {
  background: #f1f1f1;
}

.language-choice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 26px;
}

.select-shelf {
  width: calc(100% - 195px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select-shelf .select-flame {
  width: 82.3%;
  display: block;
  position: relative;
  margin-right: 31px;
}
.select-shelf .select-flame:hover:before {
  opacity: 0.5;
}
.select-shelf .select-flame:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 8px;
  background: url(/public/img/icon-arrow-bottom.png);
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  top: 50%;
  right: 19px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.select-shelf select {
  width: 100%;
  font-size: 1.4rem;
  padding: 18px 40px 18px 18px;
  border-radius: 10px;
  border: solid 1px rgba(40, 40, 40, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.01em;
}
.select-shelf select::-ms-expand {
  display: none;
}

.read-about {
  display: block;
  width: 195px;
  height: 53px;
  background: #806f4c;
  border-radius: 100px;
  border: solid 1px #806f4c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}
.read-about:hover {
  background-color: transparent;
}
.read-about:hover img {
  opacity: 0;
}
.read-about:hover .read-about__hover-icon {
  opacity: 1;
}
.read-about:hover span {
  color: #806f4c;
}
.read-about:hover span:before {
  background: #806f4c;
}

.read-about__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  right: 7px;
}
.read-about__inner img {
  width: 14px;
  margin-right: 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
}
.read-about__inner .read-about__hover-icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}
.read-about__inner span {
  color: #ffffff;
  font-size: 1.4rem;
  padding-left: 25px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.read-about__inner span:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 25px;
  background: #ffffff;
  opacity: 0.2;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.character {
  border-radius: 100px;
  border: solid 4px #d1dadf;
  width: 28px;
  height: 28px;
  position: relative;
}
.character span {
  opacity: 0;
  visibility: hidden;
  color: #e45741;
  font-size: 1.8rem;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  letter-spacing: 0;
}

.character-circle {
  stroke: #18475f;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  stroke-dasharray: 76px;
}

.character.over span {
  opacity: 1;
  visibility: visible;
}
.character.over .character-circle {
  stroke: #fceeec;
  stroke-dashoffset: 0;
}

.generation-btn {
  background: #282828;
  width: 99.8%;
  margin: 0 auto;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.8rem;
  border: solid 1px #282828;
  border-radius: 100px;
  position: relative;
  letter-spacing: 0.02em;
  left: -1px;
  cursor: pointer;
}
.generation-btn:hover {
  background: transparent;
  color: #282828;
}
.generation-btn:hover:before {
  background: url(/public/img/generation_icon_b.svg);
}
.generation-btn:before {
  position: absolute;
  content: '';
  width: 58px;
  height: 35px;
  background: url(/public/img/generation_icon_w.svg);
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  top: 50%;
  left: 56px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.about-box {
  margin-top: 162px;
}
.about-box .under-line {
  margin-bottom: 52px;
}

.abou-box__title {
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 43px;
  letter-spacing: 0;
}

.abou-box__title.abou-box__title-second {
  margin: 61px 0 35px;
  letter-spacing: -0.04em;
}

.step-shelf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: solid 1px rgba(40, 40, 40, 0.4);
}
.step-shelf:nth-of-type(1) {
  padding: 0 0 25px;
}
.step-shelf:nth-of-type(1) .step-text {
  position: relative;
  bottom: 3px;
}
.step-shelf:nth-of-type(2) {
  padding: 25.5px 0;
}
.step-shelf:nth-of-type(2) .step-text {
  position: relative;
  bottom: 5px;
}
.step-shelf:nth-of-type(3) {
  padding: 23.5px 0;
}
.step-shelf:nth-of-type(4) {
  padding: 24px 0 0;
  border: none;
}

.step-number {
  width: 122px;
  height: 51px;
  font-size: 1.8rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  border: solid 1px rgba(56, 56, 58, 0.3);
  margin-right: 55px;
  letter-spacing: 0;
}

.step-text {
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: 0em;
}

.contact-box {
  width: 91.43%;
  margin: 117px auto 0;
  padding: 106px 0 0;
  background: url(/public/img/text-contact.png) no-repeat;
  background-size: contain;
  background-position: center top;
}

.contact-head {
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 28px;
  letter-spacing: 0;
}

.contact-text {
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.05em;
}

.contact-btn {
  display: block;
  margin: 78px auto 0;
  background: #282828;
  border: solid 1px #282828;
  width: 548px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.4rem;
  border-radius: 100px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.contact-btn:hover {
  background: transparent;
  color: #282828;
}
